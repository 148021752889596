<template>
  <div>
    <div id="cart" class="fx-main fx-a-i-start" v-bind:class="isMobile">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        :opacity="0.97"
        :z-index="2147480000"
      ></loading>
      <div class="wd-100 fx fx-main container">
        <div
          class="mg-top-1 cart-checkout-options wd-100 fx fx-direct-cont"
          v-if="cartItems.length > 0"
        >
          <div>
            <span class="txt-align-center mg-0">Seu Carrinho</span>
          </div>
        </div>

        <div class="wd-100" v-if="cartItems.length > 0">
          <cart-item
            v-for="cartItem in cartItems"
            :key="
              cartItem.product.id +
              String(cartItem.variation ? cartItem.variation.id : '')
            "
            :cartItem="cartItem"
            :remover="() => removeItemFromCart(cartItem)"
          />
        </div>

        <div v-else class="fx fx-center fx-direct-cont wd-100 mg-top-1">
          <span>Ainda não existem produtos em seu carrinho.</span>
          <router-link
            :to="{ name: 'products' }"
            class="btn clickable check-all-products mg-top-1"
            >Confira todos nossos produtos</router-link
          >
        </div>

        <div
          class="wd-100 fx fx-j-c-s-b fx-a-i-c fx-wrap fx-main"
          v-if="cartItems.length > 0"
        >
          <div class="mg-2-top cart-checkout-options wd-100 fx fx-direct-cont">
            <span>Dados do Pedido </span>
          </div>

          <div
            class="wd-45 fx fx-main mg-2-top fx-end fx-direct-cont"
            v-if="cartItems.length > 0"
          >
            <div
              class="wd-100 pd-v-2-h-l-1 pd-1-em green-shadow mg-1-bot default-border coupon-block"
            >
              <div
                class="cart-checkout-options wd-100 fx fx-direct-cont mg-1-bot"
              >
                <div class="txt-align-center">
                  <span>Cupom de Desconto </span>
                </div>
              </div>
              <div class="order-form fx-main wd-100">
                <div class="fx-main fx-wrap wd-100">
                  <div class="wd-100">
                    <input
                      class="default-border shadow mg-1-bot"
                      type="text"
                      v-model="couponInput"
                      placeholder="Cupom de Desconto"
                    />
                  </div>
                  <div class="fx-main wd-100">
                    <button
                      class="clickable btn freight-btn"
                      v-on:click="addCoupon()"
                    >
                      Adicionar Cupom
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="wd-100 pd-v-2-h-l-1 pd-1-em green-shadow m-r-6 mg-1-bot"
              v-if="!tangibleStore && showShippingOnCart"
            >
              <div
                class="cart-checkout-options wd-100 fx fx-direct-cont mg-1-bot"
              >
                <div class="txt-align-center">
                  <span>Calcular o Frete</span>
                </div>
              </div>
              <the-mask
                mask="#####-###"
                type="text"
                :masked="true"
                name="business_address.zipcode"
                v-model="zipcode"
                class="default-border mg-1-bot"
                placeholder="Calcule seu frete"
              ></the-mask>

              <div
                class="btn freight-btn"
                v-on:click="calculateShipping(zipcode)"
              >
                Calcular Frete
              </div>

              <div class="sustain-freight">
                <div
                  v-for="listMethods in this.shippingMethodsList"
                  :key="listMethods.shipping_method"
                >
                  <template v-if="listMethods.shipping_method && listMethods.price < 91239124">
                    <div class="shipping-method-list mg-1-top pd-1-em">
                      <div>{{ listMethods.shipping_method | toShipping }}</div>
                      <div class="days">{{ listMethods.days }}</div>
                      <div>{{ listMethods.price | toCurrency }}</div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div
            class="fx-main fx-direct-cont mg-top-1 pd-1-em green-shadow wd-45 f-j-c-c default-border"
          >
            
            <div v-if="hasPlottedPrice" class="dsp-grid gd-tp-c-2 fx-a-i-c">
              <span class='wd-100'> Subtotal <span class="mg-left-1 normal-text"
                  >({{ productQuantity }})</span
                >
                <span class="normal-text" v-if="productQuantity <= 1"
                  >Item</span
                >
              <span class="normal-text" v-else>Itens</span> </span>
              <span class="main-product-title fx fx-a-i-c">
                <span class='price-number'> 
                {{ rawAmount | toCurrency }}</span> à vista
                
              </span>
               <span>
                 <span style="font-size: 13px">  {{ max_installments_plotted }}x  de  {{ (this.totalCartWithPlotted / max_installments_plotted) | toCurrency }} 
                   <br>no cartão de crédito </span>
                <span class="mg-left-1 total-itens"></span>
              </span>
              <span class="main-product-title fx fx-a-i-cent">
                {{ this.totalCartWithPlotted | toCurrency }}
              </span>              
            </div>
            
            <div v-if="!hasPlottedPrice" class="dsp-grid gd-tp-c-2">
              <span> 
                Subtotal   
                <span class="mg-left-1 total-itens"></span>
              </span>
              <span class="main-product-title fx">
                {{ rawAmount | toCurrency }}
                <span class="mg-left-1 normal-text"
                  >({{ productQuantity }})</span
                >
                <span class="normal-text" v-if="productQuantity <= 1"
                  >Item</span
                >
                <span class="normal-text" v-else>Itens</span>
              </span>
            </div>
            
            <div class="dsp-grid gd-tp-c-2" v-if="rawDiscount > 0">
              <span>
                Desconto Valor de Compra
              </span>
              <span class="main-product-title fx">
                {{ rawDiscount | toCurrency }} (
                  {{ discount_percentage * 100}}  % )
              </span>
            </div>  
            <div v-if="!coupons.length">
              <div class="dsp-grid gd-tp-c-2" v-if="rawDiscount">
                <span>
                  Total
                </span>
                <span class="main-product-title fx">
                  {{ rawAmount - rawDiscount | toCurrency }} 
                </span>
              </div>  
            </div>

            <div
              v-for="coupon in coupons"
              :key="coupon.id"
              class="fx fx-direct-cont"
            >
              <div class="mg-v-1-em dsp-grid gd-tp-c-2">
                Descontos
                <template v-if="coupon.modifier_type === 'PERCENTAGE'">
                  <span class="main-product-title"
                    >{{ (rawAmount * (coupon.amount / 100)) | toCurrency }} (
                    {{ coupon.code }} - {{ coupon.amount }} % )
                  </span>
                </template>
                <template
                  class="main-product-title"
                  v-if="coupon.modifier_type === 'PRICE'"
                >
                  <span class="main-product-title">
                    - {{ coupon.amount | toCurrency }} ({{ coupon.code }})</span
                  >
                </template>
              </div>

              <div
                class="dsp-grid gd-tp-c-2"
                v-if="coupon.modifier_type === 'PERCENTAGE'" 
              >
                <span>Total</span>
                <span class="main-product-title">
                  {{ ((rawAmount * (1 - coupon.amount / 100)) - rawDiscount) | toCurrency }}
                </span>
              </div>

              <div class="dsp-grid gd-tp-c-2" v-else>
                <span>Total</span>
                <span class="main-product-title">
                  {{ ((rawAmount - coupon.amount) - rawDiscount) | toCurrency }}
                </span>
              </div>
            </div>
          </div>




        </div>
      </div>
    </div>

    <div class="fixed-buttons wd-100 fx fx-j-c-s-b fx-a-i-cent mg-5-top">
      <div class="container fx fx-main">
        <button
          class="p-detail-btn clickable"
          type="button"
          @click="
            $router.zhhasBefore
              ? $router.go(-1)
              : $router.push({ name: 'home' })
          "
        >
          <i class="fas fa-chevron-left"></i> Comprar mais
        </button>
        <button
          class="p-detail-btn clickable"
          v-on:click="generateOrder()"
          v-if="cartItems.length > 0"
        >
          <span class="mg-right-1-em">Realizar Pedido</span>
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.check-all-products {
  width: 21em;
}
@media (max-width: 500px) {
  .wd-50,
  .wd-45,
  .wd-25 {
    width: 100%;
  }
  .fx-end {
    justify-content: center;
  }
}

.freight-btn {
  width: 100%;
}

.gd-tp-c-2 {
  grid-auto-columns: 40% 70% !important;
}

.m-r-6 {
  margin-right: 6.5em;
}

.sustain-freight .shipping-method-list {
  border-radius: 1em;
}
.sustain-freight {
  width: 100%;
  margin-top: 1em;
  z-index: 1;
}
</style>

<script>
import util from "@/services/util";
import HeaderBuilder from "@/services/header-builder.js";
import Coupon from "@/services/coupon";
import router from "@/router";
import CartItem from "@/components/storekeeper/CartItem.vue";
import Cart from "@/services/cart";
import store from "@/store";
import Loading from "vue-loading-overlay";
import Mobile from "@/services/mobile.js";
import Features from "@/services/features";
import { TheMask } from "vue-the-mask";
import boolean from "boolean";
import axios from "axios";
import { apiBaseUrl } from "@/services/api.js";

export default {
  name: "Cart",
  components: {
    "cart-item": CartItem,
    Loading,
    "the-mask": TheMask,
  },

  props: {
    autoConfirm: Boolean,
  },
  data: function () {
    return {
      loadIndex: 0,
      enabled_shipping_methods: [],
      cartItems: [],
      isMobile: Mobile.isMobile(),
      coupons: [],
      raw_ephemeral_result: null,
      couponInput: "",
      totalCart : 0,
      totalCartWithPlotted : 0,
      zipcode: null,
      ephemerals_by_shipping: {},
      shippingMethodsList: [],
      tangibleStore: process.env.VUE_APP_TANGIBLE_STORE || "",
      couponList: [],
      buildShipping: [],
      showShippingOnCart: boolean(process.env.VUE_APP_SHOW_SHIPPING_ON_CART),
      shippingMethodsList: [],
      purchase_amount: 15000,
      discount_percentage: 0.20,
      enabled_purchase_discount: boolean(process.env.VUE_APP_ENABLE_PURCHASE_DISCOUNT || false) ,
      max_installments_plotted: null,
    };
  },
  mounted() {


    
    
    if (
      store.state.user.localCart &&
      store.state.user.localCart.length > 0 &&
      store.state.user.logged
    ) {
      this.migrateCart();
    } else {
      this.loadCart();
    }

    let pfeat = Features.get()

      .then((features) => {
        this.shipping_method = Object.entries(features.shipping_methods)

          .filter((e) => boolean(e[1].default))
          .map((e) => e[0])[0];

        this.enabled_shipping_methods = Object.entries(
          features.shipping_methods
        )
          .filter((e) => boolean(e[1].enabled))
          .map((e) => e[0]);

        this.max_installments_plotted =
          features.payment_methods && features.payment_methods.credit_card
          ? Number(features.payment_methods.credit_card.maximum_installments_plotted)
          : null;
      })
      .finally(() => {
        this.loadIndex--;
      });

    Coupon.list().then((listOfCoupons) => {
      this.couponList = listOfCoupons;

      
    });
  },
  computed: {
    isLoading: function () {
      return this.loadIndex > 0;
    },
    rawAmount: function () {
      
      

      Cart.get().then((cart) => {
      this.productsInCart = (cart.items || []).length
       this.totalCart = (cart.items || [])
          .map((i) => Number(i.full_price))
          .reduce((a, b) => a + b, 0);
      });
      
      return this.totalCart 

    },
    rawDiscount: function () {
      if(!this.enabled_purchase_discount){
        return 0;
      }
      if(this.rawAmount < this.purchase_amount){
        return 0;
      }
      return (this.totalCart * this.discount_percentage) + 0.005;
    },
    productQuantity: function () {
      return this.cartItems
        .map((i) => Number(i.quantity))
        .reduce((a, b) => a + b, 0);
    },
    hasPlottedPrice: function () {
      let has_plotted = 0;
      this.totalCartWithPlotted = 0;
      this.cartItems.forEach(cart => {
           if (cart.product.plotted_price) {
               has_plotted = 1;
               this.totalCartWithPlotted += Number(cart.product.plotted_price * cart.quantity);
           } else {
               this.totalCartWithPlotted += Number(cart.full_price);
           }
      }) 
      return has_plotted;
    },
    ephemeral_result: function () {
      // nao remover
      this.backdoor;
      const shipping = this.shipping_method;
      const ephs = this.ephemerals_by_shipping;
      const rawRes = this.raw_ephemeral_result;
      if (!shipping) {
        return rawRes;
      }
      if (!ephs || !(ephs[shipping] || {}).result) {
        return rawRes;
      }
      return ephs[shipping].result;
    },
  },
  // final_destination_address: function () {
  //   return this.hasShipping
  //     ? this.use_other_address === true || this.force_other_address_mode
  //       ? this.destination_address
  //       : this.user.business_address
  //     : undefined;
  // },
  // hasShipping: function () {
  //   return (
  //     this.cartItems.some((i) =>
  //       !i.variation || (i.variation.parent_fields || []).includes("box")
  //         ? i.product.box
  //         : i.variation.box
  //     )
  //   );
  // },
  methods: {
    migrateCart: function () {
      this.loadIndex++;
      Cart.migrate()
        .then((migrated) => {
          if (this.autoConfirm && migrated) {
            this.generateOrder(true);
          } else {
            this.loadCart();
          }
        })
        .finally(() => {
          this.loadIndex--;
        });
    },
    loadCart: function () {
      this.loadIndex++;
      Cart.get()
        .then((cart) => {
          this.cartItems = cart.items || [];
          this.coupons = cart.coupons || [];
        })
        .finally(() => {
          this.loadIndex--;
        });
    },

    removeItemFromCart: function (item) {
      
      this.loadIndex++;
      Cart.remove(item.product.id, item.quantity, item.variation)
        .then((response) => {
          this.$root.$emit("cartUpdate");
          this.cartItems = this.cartItems.filter(
            // Apenas deixar caso:
            // - Item deletado não tenha variação E os produtos sejam diferentes
            // - Item deletado tenha variação E a variação seja diferente caso os produtos sejam iguais
            // OU ( OU não seja a mesma variação do deletado)
            (x) =>
              item.variation
                ? item.product.id != x.product.id
                  ? true
                  : item.variation.id != (x.variation || {}).id
                : item.product.id != x.product.id
          );
        })
        .finally(() => {
          this.loadIndex--;
        });
    },
    addCoupon: function () {
      let coupon_code = this.couponInput;
      Coupon.query(coupon_code, this.cartItems[0].product.supplier_id).then(
        (coupon) => {
          Coupon.apply(coupon.id).then(
            (appliedCoupon) => {
              this.coupons.push(appliedCoupon);
            },
            (error) => {
              this.$notify({
                duration : 24000,
                type: "error",
                title: "Erro ao Aplicar o Cupom!",
                text: util.stringifyAxiosError(error),
              });
            }
          );
        },
        (error) => {
          this.$notify({
            duration : 24000,
            type: "error",
            title: "Cupom não existe!",
            text: `O cupom '${coupon_code}' não existe! Confira o código do cupom!`,
          });
        }
      );
    },
    generateOrder: function (force = false) {
      if (this.loadIndex > 0 && !force) {
        return;
      }
      if (!store.state.user.logged) {
        router.push({
          name: "login",
          params: {
            then: {
              name: "cart",
              params: {
                autoConfirm: true,
              },
            },
          },
        });

        return;
      }
      router.push({
        name: "order-data",
      });
    },

    calculateShipping: function () {
      this.shippingMethodsList = [];



      

      this.cepLoadIndex++;

      this.zipcode = this.zipcode.replace(/\D/, "");

      this.enabled_shipping_methods.forEach((shippingMethod) => {
        

        const is_local_cart = !store.state.user.logged;
        const params = is_local_cart
          ? new URLSearchParams({
              cart: store.state.user.localCart,
            })
          : null;
        axios
          .get(
            `${apiBaseUrl}/api/cart/shipping?${
              params ? params.toString() + "&" : ""
            }zipcode=${this.zipcode}&shipping_method=${shippingMethod}`,
            {
              headers: HeaderBuilder.build(),
            }
          )
          .then((response) => {
            
            let v = Object.values(response.data).flat();
            let shipping_price = v
              .map((v) => Number(v.price || 0))
              .reduce((a, b) => Number(a || 0) + Number(b || 0), 0);
            let delivery_days = Math.max(...v.map((v) => Number(v.delivery_days || 0))
            )

            //==========================================================================

              //if (process.env.VUE_APP_TENANT_ID == "monteverde") {
                if (shippingMethod == "express_delivery") {
                  // var courier = new RegExp(
                  //   "^((050.+)|(010.+)|(045.+)|(046.+)|(012.+)|(013.+)|(014.+)|(040.+)|(043.+)|(042.+)|(041.+)|(011.+)|(047.+)|(015.+)|(054.+)|(056.+))$"
                  // );
                  // var resCourier = courier.test(this.zipcode);

                  // var freeShipping = new RegExp(
                  //   "^((0410.+)|(0152.+)|(0153.+)|(0154.+)|(0155.+)|(0456.+)|(0460.+)|(0455.+)|(0457.+)|(0458.+)|(0452.+)|(0407.+)|(0461.+)|(0473.+))$"
                  // );
                  // var resFreeShipping = freeShipping.test(this.zipcode);

                  // if (resFreeShipping && resCourier) {
                  //   response.data.price = 0;
                  //   shipping_price = 0;
                  //   shippingMethod = "Entrega Grátisx";
                  // }
                  
                  // if (resCourier && !resFreeShipping) {
                  //   response.data.price = 1490;
                  //   shipping_price = 1490;
                  //   shippingMethod = "Courier";
                  // }

                  // if (!resFreeShipping && !resCourier) {
                  //   response.data.price = null;
                  //   shippingMethod = null;

                  //   delete this.buildShipping;
                  // }
                  if (shipping_price == 0) {
                      shippingMethod = "Entrega Grátis";
                  } else {
                      shippingMethod = "Courier";
                  }
                }

                // this.buildShipping = {
                //   shipping_method: shippingMethod,
                //   price: response.data.price,
                //   days: response.data.delivery_days,
                // };
              //} 
              // else {
              //     if (shippingMethod == "express_delivery") {

              //           shippingMethod = "Courier";

              //     }
              //     this.buildShipping = {
              //       shipping_method: shippingMethod,
              //       price: response.data.price,
              //       days: response.data.delivery_days,
              //     };
              // }

            //==========================================================================


            this.buildShipping = {
                  shipping_method: shippingMethod,
                  price: shipping_price,
                  days: delivery_days
            }
          
          this.shippingMethodsList.push(this.buildShipping)

          

          })
          .finally(() => {
            this.cepLoadIndex--;
          });

        // Cart.shipping(this.zipcode, shippingMethod).then((response) => {

        // });
      });
    },

    // cepera: debounce(
  },
};
</script>